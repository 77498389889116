import getFlattenSteps from 'Classes/workflow/TunnelSteps';
import tunnelConstraints from 'Classes/workflow/TunnelConstraints';
import { TUNNEL_DIRECTION_BACKWARD, TUNNEL_DIRECTION_FORWARD } from 'Classes/Constants';

import { RESET_TUNNEL, SET_CURRENT_INDEX, SET_FLATTEN_STEPS, SET_TYPE, } from 'Stores/types/tunnelMutationsTypes';
import { REGISTER_TEST_BY_NAME } from 'Stores/types/ABTestActionsTypes';

import { routerResolve } from 'Services/routerServices';

export default {
  namespaced: true,

  state: {
    currentIndex: undefined,
    flattenSteps: getFlattenSteps(),
    constraints: tunnelConstraints,
  },

  getters: {
    getFlattenSteps: (state) => state.flattenSteps,
    getAccessibleFlattenSteps: (state, getters, rootState, rootGetters) => (newTypes = [], direction = undefined) => {
      // on filtre les étapes selon les contraintes de direction,
      // on filtre les étapes selon les contraintes définies
      return state.flattenSteps
        .filter((step) => (!step.direction || !direction || step.direction === direction))
        .filter((step) => {
          if (step.constraints) {
            const testConstraints = step.constraints
              .filter((constraint) => !constraint.direction || constraint.direction === direction)
              .map((constraint) => {
                const newType = newTypes.map((type) => ({
                  name: Object.entries(type)[0][0],
                  value: Object.entries(type)[0][1]
                }))
                  .find((type) => type.name === constraint.name);
                return state.constraints[constraint.name]
                  .test({
                    name: constraint.name,
                    state,
                    getters,
                    rootState,
                    rootGetters,
                    newType,
                    expectedValue: constraint.value,
                    params: constraint.params,
                    operator: constraint.operator,
                  });
              });

            return testConstraints.find((constraint) => constraint === false) === undefined;
          }
          return true;
        });
    },
    // Same as getAccessibleFlattenSteps but don't retrieve hidden (but accessible) steps
    getFilteredAccessibleFlattenSteps: (state, getters) => (newTypes, direction = 'all') => {
      return getters.getAccessibleFlattenSteps(newTypes, direction)
        .filter((step) => !step.hidden);
    },
    getCurrentIndex: (state) => state.currentIndex,
    getCurrentStep: (state, getters) => {
      const currentStep = state.flattenSteps[getters.getCurrentIndex];
      if (currentStep?.debugStore) console.info('Tunnel store debug : getCurrentStep ->', currentStep.route, currentStep);
      return currentStep;
    },
    getPrevStep: (state, getters) => (newTypes) => {
      const currentStep = getters.getCurrentStep;
      const prevSteps = getters.getFilteredAccessibleFlattenSteps(newTypes, TUNNEL_DIRECTION_BACKWARD)
        // on filtre les étapes cachées
        .filter((step) => !step.hidden)
        // on filtre les étapes qui sont avant l'étape courante
        .filter((step) => step.index < currentStep.index)
        // on filtre les étapes qui sont au même niveau ou plus bas que l'étape courante
        .filter((step) => step.depthLevel <= currentStep.depthLevel)
        .filter((step) => routerResolve(step.route));

      const prevStep = prevSteps[prevSteps.length - 1];
      if (prevStep?.debugStore) console.info('Tunnel store debug : getPrevStep ->', prevStep.route, prevStep);
      return prevStep;
    },
    getPrevStepRoute: (state, getters) => ({
      newTypes,
      params,
      query
    } = {}) => {
      const prevStep = getters.getPrevStep(newTypes);
      return prevStep ? {
        name: prevStep.route,
        params: {
          ...params,
          types: newTypes
        },
        query: { ...query, ...prevStep.query },
      } : undefined;
    },
    getNextStep: (state, getters) => (newTypes) => {
      const currentIndex = getters.getCurrentIndex;
      const nextStep = getters.getFilteredAccessibleFlattenSteps(newTypes, TUNNEL_DIRECTION_FORWARD)
        .filter((step) => !step.hidden)
        .find((step) => step.index > currentIndex);
      if (nextStep?.debugStore) console.info('Tunnel store debug : getNextStep ->', getters.getFilteredAccessibleFlattenSteps(newTypes, TUNNEL_DIRECTION_FORWARD), newTypes);
      return nextStep;
    },
    getNextStepRoute: (state, getters) => ({
      newTypes,
      params,
      query
    } = {}) => {
      const nextStep = getters.getNextStep(newTypes);
      return nextStep ? {
        name: nextStep.route,
        params: {
          ...params,
          types: newTypes
        },
        query: { ...query, ...nextStep.query },
      } : undefined;
    },
    getTypeValue: (state) => (type) => state.constraints[type]?.value,
    getStepRouteByIndex: (state) => (index, {
      params,
      query
    }) => {
      const step = state.flattenSteps.find((s) => s.index === index);
      return step ? {
        name: step.route,
        params,
        query: { ...query, ...step.query }
      } : undefined;
    },
    getStepByRouteName: (state, getters) => (routeName) => getters.getFlattenSteps.find((step) => step.route === routeName),
    getStepByName: (state, getters) => (name) => getters.getFlattenSteps.find((step) => step.name === name),
    getStepRouteByName: (state, getters) => (name, {
      params,
      query,
    } = {}) => {
      const step = getters.getStepByName(name);
      if (step?.debugStore) console.info('Tunnel store debug : getStepRouteByName ->', step.route, step);
      return step ? {
        name: step.route,
        params: { ...params },
        query: { ...query, ...step.query }
      } : undefined;
    },
    getFirstStep: (state, getters) => (newTypes) => {
      const filteredSteps = getters.getFilteredAccessibleFlattenSteps(newTypes);
      if (filteredSteps.length > 0) {
        return filteredSteps[0];
      }
      return undefined;
    },
    getFirstStepRoute: (state, getters) => ({
      newTypes,
      params,
      query
    } = {}) => {
      const firstStep = getters.getFirstStep(newTypes);
      return firstStep ? {
        name: firstStep.route,
        params: {
          ...params,
          types: newTypes
        },
        query: { ...query, ...firstStep.query },
      } : undefined;
    },
    getLastStep: (state, getters) => (newTypes) => {
      const filteredSteps = getters.getFilteredAccessibleFlattenSteps(newTypes);
      if (filteredSteps.length > 0) {
        return filteredSteps[filteredSteps.length - 1];
      }
      return undefined;
    },
    getLastStepRoute: (state, getters) => ({
      newTypes,
      params,
      query
    } = {}) => {
      const lastStep = getters.getLastStep(newTypes);
      return lastStep ? {
        name: lastStep.route,
        params: {
          ...params,
          types: newTypes
        },
        query: { ...query, ...lastStep.query },
      } : undefined;
    },
  },

  mutations: {
    [SET_CURRENT_INDEX](state, index) {
      state.currentIndex = index;
    },
    [SET_FLATTEN_STEPS](state, value) {
      state.flattenSteps = value;
    },
    [SET_TYPE](state, {
      name,
      value
    }) {
      state.constraints[name].value = value;
    },
    [RESET_TUNNEL](state) {
      state.currentIndex = undefined;
      Object.keys(state.constraints)
        .forEach((key) => {
          state.constraints[key].value = state.constraints[key].default;
        });
    },
  },

  actions: {
    async init({
      commit,
      rootState,
    }) {
      const check = ({ resolve }) => {
        const isUserLoaded = rootState.temporaryCustomer.isLoaded || rootState.user.isLoaded;
        const isAppReady = rootState.app.isReady;

        const loadingCondition = isUserLoaded && isAppReady;

        if (loadingCondition) {
          setTimeout(() => {
            commit(SET_FLATTEN_STEPS, getFlattenSteps());
            setTimeout(() => {
              ABTestPromise.then(() => resolve());
            });
          });
        }
      };
      // On vérifie que nous ne sommes pas en train de charger des données
      // Si oui, on attend la fin du chargement.
      // Utile par exemple lorsque l'on arrive directement sur la page d'inscription du tunnel
      // l'init permet d'attendre d'avoir chargé les données du temporaryCustomer
      // si on ne les attend pas, le TunnelWokflowGuard nous fait revenir en arrière dans le tunnel
      const loadingUserPromise = new Promise((resolve) => {
        check({ resolve });

        this.watch((state) => state.temporaryCustomer.isLoaded, () => check({ resolve }));
        this.watch((state) => state.user.isLoaded, () => check({ resolve }));
        this.watch((state) => state.app.isReady, () => check({ resolve }));
      });

      const ABTestPromise = new Promise((resolve) => {
        const abTestContraints = this.getters['tunnel/getFlattenSteps']
          .filter((step) => step.constraints?.find((constraint) => constraint.type === 'abTest'))
          .map((step) => step.constraints?.find((constraint) => constraint.type === 'abTest'));

        if (abTestContraints.length > 0) {
          Promise.all(abTestContraints
            .map((abTestConstraint) => this.dispatch(`ABTest/${REGISTER_TEST_BY_NAME}`, abTestConstraint.params.key)))
            .catch((err) => {
              console.error('Tunnel store error : init | abTestContraints ->', err);
            })
            .finally(() => resolve());
        } else {
          resolve();
        }
      });

      return loadingUserPromise
        .catch((err) => {
          console.error('Tunnel store error : init | loadingUserPromise ->', err);
        });
    },
  },
};
