import { formatInTimeZone } from 'date-fns-tz';
import { sha256 } from 'js-sha256';

import { calcSlug } from 'Classes/Tools';
import { CANCELLED } from 'Classes/OrderStatus';
import { CURRENCY_CODE as currencyCode, timeZone } from 'Classes/Constants';
import BoxGtmAdapter from 'Classes/adapters/BoxGtmAdapter';
import PriceTools from 'Classes/PricesTools';
import beginCheckoutGtmData from 'Classes/adapters/BeginCheckoutGtmAdapter';
import { PAYMENT_METHODS_INTEGRATIONS } from 'Classes/paymentMethods/Constants';
import addPaymentInfoGtmData from 'Classes/adapters/PaymentInfoGtmAdapter';

import { mapGetters } from 'vuex';

const TRANSACTION = 'transaction';
const TRANSACTION_COMPLETE = 'purchase';
const CHOOSE_SUBSCRIPTION_BOX = 'chooseSubscriptionBox';
const SEARCH_MAP = 'searchMapRequest';
const DELIVERY_POINT_DISPLAYED = 'deliveryPointDisplayed';
const DELIVERY_CHOICE = 'deliveryChoice';
const DELIVERY_INFO = 'add_shipping_info';
const BOX_LIST_IMPRESSION = 'view_item_list';
const BOX_IMPRESSION = 'view_item';
const VIEW_CART = 'view_cart';
const REFUND = 'refund';
const PAYMENT_INFO = 'add_payment_info';

const DASHBOARD_SLIDER = 'slideboutique';

export const getSectionsFromBoxId = (sections = [], boxId = -1) => sections
  .filter((section) => section.items.find((item) => item.id === boxId))
  .reduce((a, b) => (a ? `${a} - ${b.title}` : b.title), null);

export default {
  computed: {
    ...mapGetters('user', [
      'getEmail',
      'getRegionId',
    ]),
    ...mapGetters('subscriptionBasket', [
      'getCustomerSubscription',
      'getPriceSubscription',
      'getCoupons',
    ]),
    ...mapGetters('dashboard', [
      'getDashboardSections',
    ]),
    ...mapGetters('basket', {
      getBasketProducts: 'getProducts',
      getBasketSubTotal: 'getSubTotal',
    }),
  },

  methods: {
    trackBoxesImpression(section) {
      try {
        const items = section.items
          .filter((box) => box.itemType !== 'thumbnail')
          .map((box) => new BoxGtmAdapter(this.$store, { box }));

        this.$gtm.trackEvent({
          event: BOX_LIST_IMPRESSION,
          item_list_name: section.title,
          item_list_id: calcSlug(section.title),
          items,
        });
      } catch (e) {
        console.error(e);
      }
    },
    trackBoxImpression(box) {
      try {
        const items = [new BoxGtmAdapter(this.$store, { box })];
        const list = getSectionsFromBoxId(this.getDashboardSections, box.id);
        const price = PriceTools.getPriceBox(box.priceCategory, box);
        this.$gtm.trackEvent({
          event: BOX_IMPRESSION,
          currency: currencyCode,
          value: parseFloat(price),
          items,
          list,
        });
      } catch (e) { /* Nothing */
      }
    },
    trackViewCart() {
      try {
        const items = this.getBasketProducts.map((b) => new BoxGtmAdapter(this.$store, { box: b }));
        const value = parseFloat(this.getBasketSubTotal);

        this.$gtm.trackEvent({
          event: VIEW_CART,
          currency: currencyCode,
          value,
          items,
        });
      } catch (e) {
        /* Nothing */
      }
    },
    trackRefundTransaction(order) {
      try {
        const revenue = PriceTools.getPriceOrder(order.priceCategory, order).total;
        const coupon = order.coupons.map((c) => c.couponCode)
          .join(', ');
        const {
          boxes,
          id,
        } = order;
        const groupedBoxes = boxes.reduce((acc, box) => {
          const key = `${box.id}-${box.isComplementary}`;
          if (!acc[key]) acc[key] = [];
          acc[key].push(box);
          return acc;
        }, {});
        const products = Object.values(groupedBoxes)
          .map((b) => new BoxGtmAdapter(this.$store, {
            box: b[0],
            parameters: { quantity: b.length },
          }));
        const isCQJV = !Object.values(groupedBoxes)
          .map((b) => b[0])
          .find((b) => !b.isComplementary);
        const shipping = PriceTools.getShippingCostOrder(order);

        // GTM
        this.$gtm.trackEvent({
          event: REFUND,
          transaction_id: id,
          value: parseFloat(revenue),
          shipping: parseFloat(shipping),
          currency: currencyCode,
          coupon,
          isCQJV,
          items: products,
        });
      } catch (err) {
        console.error(err);
      }
    },
    trackSubscriptionTransaction(userId, subscription, coupons, prices) {
      try {
        const products = new BoxGtmAdapter(this.$store, { box: subscription.product });
        const coupon = coupons.map((c) => c.couponCode)
          .join(', ');
        const revenue = parseFloat(prices.total);
        const date = new Date(); // Obtient la date actuelle
        const formattedDate = formatInTimeZone(date, timeZone, 'yyyyMMddHHmmss'); // Formate la date
        const id = `${sha256(String(userId))}-${subscription.product.id}-${formattedDate}`;

        // GTM
        this.$gtm.trackEvent({
          event: TRANSACTION,
          currency: currencyCode,
          purchase: {
            actionField: {
              id,
              revenue,
              coupon,
            },
            products,
          },
        });
      } catch (e) {
        /* Nothing */
      }
    },
    trackCheckoutStep() {
      try {
        this.$gtm.trackEvent(beginCheckoutGtmData(this.$store));
      } catch (e) { /* Nothing */
      }
    },
    trackPurchaseSuccess(order, user, subscription, cardId) {
      try {
        const groupedBoxes = order.boxes.reduce((acc, box) => {
          const key = `${box.id}-${box.isComplementary}`;
          if (!acc[key]) acc[key] = [];
          acc[key].push(box);
          return acc;
        }, {});

        const primaryPaymentMethodTemp = user.blueCards.filter((bluecard) => bluecard.id === cardId)[0];
        const primaryPaymentMethod = {
          brand: primaryPaymentMethodTemp.brand,
          isExpired: primaryPaymentMethodTemp.isExpired,
        };

        const edenredAccount = user.edenredAccount?.usableForSubscription && 'edenred';
        const swileAccount = user.swileAccount?.usableForSubscription && 'swile';
        const paygreenCard = user.paygreenCards?.find((card) => card.usableForSubscription) && PAYMENT_METHODS_INTEGRATIONS.PAYGREEN_CARD;
        const secondaryPaymentMethod = edenredAccount || swileAccount || paygreenCard;

        let revenue = null;
        let coupon = null;
        let products = null;

        if (order && order.status !== CANCELLED) {
          revenue = PriceTools.getPriceOrder(order.priceCategory, order).total;
          coupon = order.coupons.map((c) => c.couponCode)
            .join(', ');
          products = Object.values(groupedBoxes)
            .map((b) => new BoxGtmAdapter(this.$store, {
              box: b[0],
              parameters: { quantity: b.length },
            }));
        } else if (subscription) {
          revenue = subscription.product.price.subscription;
          products = [subscription.product].map((b) => new BoxGtmAdapter(this.$store, { box: b }));

          if (this.getPriceSubscription && this.getCoupons) {
            coupon = this.getCoupons ? this.getCoupons.map((c) => c.couponCode)
              .join(', ') : coupon;
            revenue = this.getPriceSubscription.total ? parseFloat(this.getPriceSubscription.total) : revenue;
          }
        }

        const { totalPaidOrders } = user;
        const isSubscribe = user.subscription.status;
        const shipping = PriceTools.getShippingCostOrder(order);

        this.$gtm.trackEvent({
          event: TRANSACTION_COMPLETE,
          transaction_id: order.id,
          value: parseFloat(revenue),
          shipping: parseFloat(shipping),
          coupon,
          items: products,
          currency: currencyCode,
          paymentMethods: {
            primary: primaryPaymentMethod,
            secondary: secondaryPaymentMethod,
          },
          totalPaidOrders,
          isSubscribe,
        });
      } catch (e) {
        /* Nothing */
      }
    },
    trackMapSearch(result, value, type) {
      try {
        const searchMap = {
          type,
          value,
          path: this.$route.fullPath,
          result: result || 'null',
        };

        const trackEvent = {
          event: SEARCH_MAP,
          searchMap,
        };

        this.$gtm.trackEvent(trackEvent);
      } catch (e) {
        /* Nothing */
      }
    },
    trackSubscriptionBoxChoice(box) {
      try {
        this.$gtm.trackEvent({
          event: CHOOSE_SUBSCRIPTION_BOX,
          fields: {
            box: {
              name: box.name,
            },
          },
        });
      } catch (e) {
        /* Nothing */
      }
    },
    trackDeliveryPointDisplayed(dlp, context) {
      try {
        const event = DELIVERY_POINT_DISPLAYED;
        const {
          groupName: name,
          groupDeliveryAddress: address,
          private: isPrivate,
        } = dlp;
        const location = address.match(/\d{5}/g)?.[0]; // Zipcode
        const deliveryPoint = {
          name,
          address,
          isPrivate,
          location,
          deliveryDayNumber: dlp.groupDeliveryDay.length,
        };

        this.$gtm.trackEvent({
          event,
          deliveryPoint,
        });
      } catch (e) {
        /* Nothing */
      }
    },
    trackDashboardSlider(position, click, label) {
      try {
        const event = DASHBOARD_SLIDER;

        this.$gtm.trackEvent({
          event,
          dashboardSlider: {
            position,
            click,
            label,
          },
        });
      } catch (e) {
        /* Nothing */
      }
    },
    trackDeliveryChoice({
      type,
      search,
      params = {},
    }) {
      try {
        const event = DELIVERY_CHOICE;

        this.$gtm.trackEvent({
          event,
          type,
          search,
          params: {
            path: this.$route.fullPath,
            ...params,
          },
        });
      } catch (e) {
        /* Nothing */
      }
    },
    trackDeliveryInfo({
      type,
      params = {},
    }) {
      try {
        this.$gtm.trackEvent({
          event: DELIVERY_INFO,
          shipping_tier: type,
          params: {
            path: this.$route.fullPath,
            ...params,
          },
        });
      } catch (e) {
        /* Nothing */
      }
    },
    trackPaymentInfo({
      payment_type,
      params = {},
    }) {
      try {
        const gtmData = addPaymentInfoGtmData(this.$store);
        this.$gtm.trackEvent({
          ...gtmData,
          event: PAYMENT_INFO,
          payment_type,
          params: {
            path: this.$route.fullPath,
            ...params,
          },
        });
      } catch (e) { /* Nothing */
      }
    },
    trackAction({
      event,
      name,
      value,
      params = {},
    }) {
      try {
        this.$gtm.trackEvent({
          event,
          eventLabel: name,
          eventValue: value,
          params: {
            path: this.$route.fullPath,
            ...params,
          },
        });
      } catch (e) {
        /* Nothing */
      }
    },
  },
};
