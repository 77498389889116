<template>
  <div
    v-if="skeleton"
    class="card-locality w-1/2 skeleton" />

  <potager-tooltip
    v-else-if="box && (origins.length >= 1 || primaryTag || brandImage)"
    :disable="origins.length <= 1"
    :message="tooltip"
    class="card-locality text-xs.5 font-semibold flex items-center"
    tag="div"
    theme="dark">
    <potager-badge
      v-if="primaryTag"
      :theme="primaryTag.color"
      class="mr-2"
      rounded
      size="small">
      {{ primaryTag.label }}
    </potager-badge>

    <template
      v-if="brandImage">
      <potager-picture
        :src="brandImage"
        class="card-locality__brand w-[25px] h-full mr-1" />

      <template v-if="showBrandName">
        {{ brandName }}
      </template>
    </template>

    <div
      v-else
      class="line-clamp-1 gap-1 flex items-center">
      <icon-flag-france-rounded
        v-if="isFrance"
        :class="[
          'card-locality__icon',
          'p-[5px] bg-white rounded-full inline-block text-[25px] leading-0',
        ]" />

      <span
        v-if="showMainOrigin"
        class="card-locality__main leading-tight py-1 text-ellipsis bp375:truncate">
        {{ mainOrigin }}
      </span>

      <span
        v-if="complementaryOrigin"
        class="card-locality__complementary leading-tight py-1 truncate mr-1"
        v-html="complementaryOrigin" />
    </div>
  </potager-tooltip>
</template>

<script>
import PotagerTooltip from 'UI/PotagerTooltip';
import IconFlagFranceRounded from 'Icons/color/IconFlagFranceRounded';
import PotagerPicture from 'UI/PotagerPicture';
import PotagerBadge from 'UI/PotagerBadge';

import { getBoxSuppliersArray } from 'Classes/BoxesTools';

export default {
  name: 'CardLocality',
  components: {
    PotagerBadge,
    PotagerPicture,
    PotagerTooltip,
    IconFlagFranceRounded,
  },

  props: {
    box: {
      type: Object,
      required: false,
      default: undefined,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    skeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    origins() {
      if (!this.box) return [];

      const countries = [];
      const origins = [];

      this.box.products
        .forEach((product) => {
          const origin = product.origin || product.supplier?.origin;
          const country = product.country || product.supplier?.country || origin;
          if (country && !countries.includes(country)) countries.push(country);
          if (origin && !origins.includes(origin)) {
            origins.push(origin.split(',')
              .pop());
          }
        });

      if (origins.length > 1) {
        return countries.sort((a, b) => {
          if (a.toLowerCase() === 'france') return -1;
          if (b.toLowerCase() === 'france') return 1;
          return 0;
        });
      }

      return origins;
    },
    suppliers() {
      if (!this.box) return [];
      return getBoxSuppliersArray(this.box);
    },
    brandImage() {
      const seenImages = new Set();
      return this.suppliers.find((supplier) => {
        if (supplier.brandImage && !seenImages.has(supplier.brandImage)) {
          seenImages.add(supplier.brandImage);
          return true;
        }
        return false;
      })?.brandImage;
    },
    brandName() {
      const seenNames = new Set();
      return this.suppliers
        .filter((supplier) => {
          if (supplier.substituteName && !seenNames.has(supplier.substituteName)) {
            seenNames.add(supplier.substituteName);
            return true;
          }
          return false;
        })
        .map((supplier) => supplier.substituteName)
        .join(', ');
    },
    isFrance() {
      if (!this.box) return false;
      return this.box.countries?.map((locality) => locality.toLowerCase())
        .includes('france');
    },
    mainOrigin() {
      return this.origins[0];
    },
    complementaryOrigin() {
      return this.origins.length > 1 ? `+&nbsp;${this.origins.length - 1} pays` : '';
    },
    tooltip() {
      return this.origins.map((locality) => `<span class="whitespace-nowrap">${locality}</span>`)
        .join('<br />');
    },
    showMainOrigin() {
      const isIconAndWord = this.isFrance;
      const hasSupInfos = this.box?.isLocal || this.complementaryOrigin;
      return !this.small || (!isIconAndWord || (isIconAndWord && !hasSupInfos));
    },
    showBrandName() {
      const hasSupInfos = this.box?.isLocal || this.complementaryOrigin;
      return !this.small || !hasSupInfos;
    },
    primaryTag() {
      const primaryTag = !this.small ? this.box?.tags?.find((tag) => tag.type === 'primary') : null;
      const localTag = this.box?.isLocal ? {
        label: 'Local',
        color: 'british-racing',
      } : null;
      const voucherTag = this.box?.isVoucher ? {
        label: 'Bons d\'achat',
        color: 'british-racing',
      } : null;

      return primaryTag || localTag || voucherTag;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-locality {
  $this: &;
  @apply h-[25px] mb-2;
}
</style>

<style lang="scss">
.card-locality {
  .card-v2--landscape & {
    @apply bp425:h-[21px];

    &__icon {
      @apply bp425:text-xs.5 bp425:p-1 bp425:leading-0;
    }

    &__brand {
      @apply bp425:w-[21px];
    }
  }
}
</style>
